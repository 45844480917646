










































import { defineComponent, ref } from '@vue/composition-api';
import { ContactCard, ConcactTypeEvent } from '@ligo/bv-flow/store';
import { ChatBase } from '@ligo/shared/utils';
import RatingsCard from '../layout/RatingsCard.vue';

const PHONE = '+310203031043';
const CONTACT_US_IMG = '/questionnaire/images/contact_us.png';

export default defineComponent({
  name: 'NewContactCard',
  components: { RatingsCard },
  setup() {
    const contactCard = ref<ContactCard>({
      title: 'contact_card.need_help',
      subtitle: 'contact_card.talk_via_chat',
      contacts: [
        '/questionnaire/images/lisa4.png',
        '/questionnaire/images/wendy4.png',
        '/questionnaire/images/matias4.jpeg'
      ],
      sections: [
        {
          title: 'contact_card.talk_via_chat',
          contacts: [
            {
              icon: 'img:/questionnaire/icons/message.svg',
              text: 'contact_card.open_chat',
              event: ConcactTypeEvent.CHAT
            },
            {
              icon: 'img:/questionnaire/icons/icon_phone.svg',
              text: 'contact_card.make_call',
              event: ConcactTypeEvent.PHONE
            },
            {
              icon: 'img:/questionnaire/icons/calendar-alt_1_.svg',
              text: 'contact_card.schedule_meeting',
              event: ConcactTypeEvent.MEETING
            }
          ]
        }
      ]
    });

    function handleEventClick(event: ConcactTypeEvent) {
      switch (event) {
        case ConcactTypeEvent.PHONE:
          window.open(`tel:${PHONE}`, '_self');
          break;

        case ConcactTypeEvent.CHAT:
          ChatBase.openWidget();
          break;

        case ConcactTypeEvent.MEETING:
          window.open(
            'https://get.ligo.nl/book-a-meeting?utm_source=flow&utm_medium=cta'
          );
          break;

        default:
          console.log('Unknown event');
          break;
      }
    }

    return {
      contactCard,
      handleEventClick,
      CONTACT_US_IMG
    };
  }
});
