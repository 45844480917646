import { Notify } from 'quasar';

export enum NotificationType {
  error,
  success,
  warning
}

type NotifyOpts = Parameters<Notify['create']>[0];

const DEFAULT_SUCCESS = {
  type: 'positive',
  color: 'default',
  icon: 'fas fa-circle-check',
  classes: 'success-toast',
  position: 'top'
};

const DEFAULT_ERROR = {
  type: 'negative',
  color: 'error',
  icon: 'fas fa-circle-exclamation',
  position: 'top'
};

const DEFAULT_WARNING = {
  type: 'warning',
  color: 'yellow',
  icon: 'fas fa-circle-exclamation',
  position: 'top',
  timeout: 0
};

export function notifyAsync(
  promise: Promise<any>,
  success?: NotifyOpts,
  error?: NotifyOpts
) {
  promise
    .then(() => {
      const opts = typeof success === 'string' ? { message: success } : success;
      Notify.create({ ...DEFAULT_SUCCESS, ...opts } as NotifyOpts);
    })
    .catch(() => {
      const opts = typeof error === 'string' ? { message: error } : error;
      Notify.create({ ...DEFAULT_ERROR, ...opts } as NotifyOpts);
    });
}

export function simpleNotification(
  notification: NotifyOpts,
  type = NotificationType.success
) {
  if (type === NotificationType.success) {
    const opts =
      typeof notification === 'string'
        ? { message: notification }
        : notification;
    return Notify.create({ ...DEFAULT_SUCCESS, ...opts } as NotifyOpts);
  }

  if (type === NotificationType.error) {
    const opts =
      typeof notification === 'string'
        ? { message: notification }
        : notification;
    return Notify.create({ ...DEFAULT_ERROR, ...opts } as NotifyOpts);
  }

  const opts =
    typeof notification === 'string' ? { message: notification } : notification;

  return Notify.create({ ...DEFAULT_WARNING, ...opts } as NotifyOpts);
}
