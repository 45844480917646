import { BvServiceCard, ServiceCard } from '@ligo/bv-flow/store';

export const servicesCard: ServiceCard[] = [
  {
    icon: 'img:/questionnaire/icons/suitcase.svg',
    name: 'bv_plus_holding',
    link: 'DutchBV',
    type: 'dutch-bv'
  },
  {
    icon: 'img:/questionnaire/icons/rocket.svg',
    name: 'dutch-bv',
    link: 'DutchBV'
  },
  {
    icon: 'img:/questionnaire/icons/holding.svg',
    name: 'holding',
    link: 'Holding'
  },
  {
    icon: 'img:/questionnaire/icons/rocket_english.svg',
    name: 'english-bv',
    link: 'EnglishBV'
  },
  {
    icon: 'img:/questionnaire/icons/incorporate_holding.png',
    name: 'incorporate-holding',
    link: 'IncorporateHolding'
  },
  {
    icon: 'img:/questionnaire/icons/convert.svg',
    name: 'omzetting',
    link: 'Omzetting'
  },
  {
    icon: 'img:/questionnaire/icons/transfer.svg',
    name: 'transfer_of_shares',
    link: 'TransferOfShares'
  },
  {
    icon: 'img:/questionnaire/icons/trade1.svg',
    name: 'trademark_registration',
    link: 'https://www.ligo.nl/merkregistratie',
    internalLink: false
  },
  {
    icon: 'img:/questionnaire/icons/check2.svg',
    name: 'trademark_monitoring',
    link: 'https://www.ligo.nl/merkbewaking',
    internalLink: false
  },
  {
    icon: 'img:/questionnaire/icons/trade2.svg',
    name: 'trademark_search',
    link: 'https://www.ligo.nl/merkbewaking',
    internalLink: false
  },
  {
    icon: 'img:/questionnaire/icons/check3.svg',
    name: 'draft_terms',
    link: 'https://www.ligo.nl/algemene-voorwaarden-controleren',
    internalLink: false
  },
  {
    icon: 'img:/questionnaire/icons/check1.svg',
    name: 'check_terms',
    link: 'https://www.ligo.nl/algemene-voorwaarden-controleren',
    internalLink: false
  },
  {
    icon: 'img:/questionnaire/icons/debt.svg',
    name: 'debt',
    link: 'https://www.ligo.nl/incassobureau',
    internalLink: false
  }
];

export const bvServicesCard: BvServiceCard[] = [
  {
    nl: {
      icon: 'img:/questionnaire/icons/rocket.svg',
      link: 'DutchBV',
      type: 'dutch-bv'
    },
    'en-us': {
      icon: 'img:/questionnaire/icons/rocket_english.svg',
      link: 'EnglishBV',
      type: 'english-bv'
    },
    name: 'bv'
  },
  {
    nl: {
      icon: 'img:/questionnaire/icons/suitcase.svg',
      link: 'DutchBV',
      type: 'dutch-bv'
    },
    'en-us': {
      icon: 'img:/questionnaire/icons/suitcase.svg',
      link: 'EnglishBV',
      type: 'english-bv'
    },
    name: 'bv_plus_holding'
  },
];
