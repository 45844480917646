























import { defineComponent, PropType } from '@vue/composition-api';
import { AuthError } from '@ligo/bv-flow/store';

export default defineComponent({
  name: 'BannerError',
  props: {
    error: { type: Object as PropType<AuthError> },
    redirectUrl: String
  }
});
