const date = new Date();
const this_year = `31-12-${date.getFullYear().toString()}`;
const next_year = `31-12-${(date.getFullYear() + 1).toString()}`;

export default {
  labels: {
    company_name: 'Wat zal de naam zijn van je nieuwe BV?',
    email: 'Emailadres contactpersoon',
    headquarters: 'Statutaire zetel',
    office_address: 'Adres',
    place_of_business: '',
    postal_code: '',
    activities: 'Beschrijf de activiteiten van de onderneming',
    sell_products:
      'Verkoopt de onderneming aan consumenten of aan ondernemingen?',
    where_are_sold:
      'Waar worden de producten verkocht? (selecteer the opties die van toepassing zijn)',
    export_products: 'Exporteert de onderneming producten en/of diensten?',
    import_products: 'Importeert de onderneming producten en/of diensten?',
    number_of_shares: 'Totaal aantal aandelen',
    value_of_shares: 'Wat wordt de waarde van een aandeel?',
    financial_year_end:
      'Wanneer eindigt het eerste boekjaar van de op te richten onderneming?',
    full_time_work:
      'Hoeveel mensen werken full-time (15 uur of meer per week) bij de op te richten BV?',
    part_time_work:
      'Hoeveel mensen werken part-time (minder dan 15 uur per week) bij de op te richten BV?',
    employees_loan: 'Is er sprake van het incidenteel uitlenen van werknemers?',
    representation:
      'Op welke manier kan de bestuurder de BV vertegenwoordigen?',
    custom_notary: 'Notaris die de legalisatie zal verzorgen',
    custom_notary_city: 'Jouw gebied',
    lead_approval:
      'Wil je gratis en vrijblijvend 3 offertes te ontvangen van door ons geselecteerde boekhouders uit uw regio?',
    incorporation_speed: 'Wil je jouw bedrijf snel oprichten?'
  },
  placeholders: {
    email: 'Emailadres contactpersoon',
    headquarters: 'Vul de plaats in waar het hoofdkantoor is gevestigd',
    office_address: 'Vul de straatnaam en het huisnummer in',
    place_of_business:
      'Vul de plaats in waar het fysieke kantoor is gevestigd ',
    company_name: 'Vul de naam van je holding in',
    holding_name: 'Type here your holding name',
    postal_code: 'Vul de postcode in',
    activities: 'Beschrijf de activiteiten van de onderneming....',
    sell_products: 'Kies uit de lijst',
    where_are_sold: 'Kies uit de lijst',
    value_of_shares: 'Vul een waarde in',
    enter_number: 'Voer nummer in',
    financial_year_end: '',
    full_time_work: 'Voer hier het aantal in',
    part_time_work: 'Voer hier het aantal in',
    number_of_shares: 'Totaal aantal aandelen',
    shares: 'Voer nummer in',
    custom_notary:
      'Vermeld hier de naam, plaats en telefoonnummer van de notaris die de legalisatie zal uitvoeren:',
    custom_notary_city:
      'Wat is de plaats waar je graag bij een notaris langs wil gaan?'
  },
  options: {
    sell_products: {
      consumers: 'Aan consumenten',
      companies: 'Aan ondernemingen',
      none: 'Niet van toepassing',
      consumers_and_businesses: 'Aan zowel consumenten als ondernemingen'
    },
    where_are_sold: {
      store_or_kiosk: 'In een winkel of kiosk',
      market: 'Op de markt',
      street_trade: 'Via straathandel',
      internet: 'Via internet',
      from_home: 'Vanuit huis',
      postorder: 'Per postorder',
      not_applicable: 'Niet van toepassing'
    },
    representation: {
      one_signature:
        'De bestuurder is zelfstandig (alleen) vertegenwoordigingsbevoegd',
      two_signatures:
        'De bestuurder is samen met een mede-bestuurder vertegenwoordigingsbevoegd'
    },
    financial_year_end: {
      this_year: this_year,
      next_year: next_year
    },
    incorporation_speed: {
      medium: 'Ja, ik wil mijn oprichting in vijf werkdagen (+€500).',
      slow: 'Nee, oprichting in 15 werkdagen is prima (inbegrepen)'
    }
  },
  label_tooltip: {
    company_name:
      "<span>De naam moet uniek zijn, zodat het herkenbaar is. Controleer via <a href='http://kvk.nl/zoeken'>kvk.nl/zoeken</a> of de naam al bestaat. Zo voorkom je problemen met bedrijven die dezelfde, of vergelijkbare naam hebben. Ben je nog niet zeker van de naam? Geen zorgen. De naam kan je later in het proces nog aanpassen. </span>",
    office_address:
      'Weet je nog niet waar je de holding wil vestigen? Registreer de holding dan op je woonadres. Dit scheelt papierwerk en tijd. De notaris heeft dan geen huurovereenkomst nodig om je holding op te richten. Je kan het adres na oprichting nog kosteloos en eenvoudig zelf wijzigen via de KVK. <br>Let op: het adres van de holding moet een fysiek Nederlands adres zijn en kan geen virtueel kantooradres of briefadres zijn.',
    headquarters:
      "De statutaire zetel is vaak hetzelfde als de vestigingsplaats van de holding en bepaalt welke rechter bevoegd is bij een geschil. De zetel wordt ook wel de formele 'woonplaats' van de holding genoemd en zal worden opgenomen in je statuten.",
    financial_year_end:
      'De meeste ondernemers kiezen ervoor om het eerste boekjaar aan het eind van het volgende jaar te laten aflopen om tijd en kosten te besparen.',
    value_of_shares:
      'Je kan hier geen "0" invullen. De waarde van 1 aandeel dient minimaal 0,01 euro te bedragen.',
    total_shares:
      'Vul hier het totale aantal aandelen in. Deze persoon zal 100% van de aandelen hebben.',
    incorporation_speed:
      'Op dit moment is het erg druk bij notarissen in heel Nederland en de bij de Kamer van Koophandel. Het moment van oprichting telt vanaf het moment dat alle documenten zijn goedgekeurd'
  }
};
