const date = new Date();
const this_year = `31-12-${date.getFullYear().toString()}`;
const next_year = `31-12-${(date.getFullYear() + 1).toString()}`;

export default {
  labels: {
    company_name: 'Wat zal de naam zijn van je nieuwe BV?',
    email: 'Emailadres contactpersoon',
    headquarters: 'Statutaire zetel',
    office_address: 'Adres',
    place_of_business: '',
    postal_code: '',
    industry: 'In welke sector zal je BV actief zijn? ',
    caregiver:
      'Werk je direct met patiënten en/of factureer je direct aan een zorgverzekeraar?',
    big_registration:
      'Ben je als zorgverlener geregistreerd in het BIG-register?',
    activities: 'Beschrijf de activiteiten van de onderneming',
    sell_products:
      'Verkoopt de onderneming aan consumenten of aan ondernemingen?',
    where_are_sold:
      'Waar worden de producten verkocht? (selecteer the opties die van toepassing zijn)',
    export_products: 'Exporteert de onderneming producten en/of diensten?',
    import_products: 'Importeert de onderneming producten en/of diensten?',
    number_of_shares: 'Totaal aantal aandelen',
    value_of_shares: 'Wat wordt de waarde van een aandeel?',
    financial_year_end:
      'Wanneer eindigt het eerste boekjaar van de op te richten onderneming?',
    full_time_work:
      'Hoeveel mensen werken full-time (15 uur of meer per week) bij de op te richten BV?',
    part_time_work:
      'Hoeveel mensen werken part-time (minder dan 15 uur per week) bij de op te richten BV?',
    employees_loan: 'Is er sprake van het incidenteel uitlenen van werknemers?',
    representation:
      'Op welke manier kan de bestuurder de BV vertegenwoordigen?',
    custom_notary: 'Notaris die de legalisatie zal verzorgen',
    custom_notary_city: 'Jouw gebied',
    added_value: 'Heeft jouw eenmanszaak of VOF meerwaarde?',
    accountant: 'Heb je een accountant of fiscalist?',
    holding_structure:
      'Richt je een holdingstructuur op (persoonlijke holding en werk-BV)?',
    purchase_agreement:
      'Wil je de Koopovereenkomst passiva-activa regelen, welke nodig is voor je omzetting?',
    free_intake: 'Wil je een gratis intakegesprek met een Ligo accountant?',
    converted_from: 'Wat wordt er omgezet naar een BV?',
    lead_approval:
      'Wil je gratis en vrijblijvend 3 offertes te ontvangen van door ons geselecteerde boekhouders uit uw regio?',
    type_of_conversion:
      'Op welke manier wil je jouw eenmanszaak of VOF omzetten?',
    incorporation_speed: 'Wil je jouw bedrijf snel oprichten?'
  },
  placeholders: {
    email: 'Emailadres contactpersoon',
    headquarters: 'Vul de plaats in waar het hoofdkantoor is gevestigd',
    office_address: 'Vul de straatnaam en het huisnummer in',
    place_of_business:
      'Vul de plaats in waar het fysieke kantoor is gevestigd ',
    company_name: 'Vul de naam van jouw BV in',
    holding_name: 'Type here your holding name',
    postal_code: 'Vul de postcode in',
    industry: 'Type bedrijfstak invoeren.',
    activities: 'Beschrijf de activiteiten van de onderneming....',
    sell_products: 'Kies uit de lijst',
    where_are_sold: 'Kies uit de lijst',
    value_of_shares: 'Vul een waarde in',
    enter_number: 'Voer nummer in',
    financial_year_end: '',
    full_time_work: 'Voer hier het aantal in',
    part_time_work: 'Voer hier het aantal in',
    number_of_shares: 'Totaal aantal aandelen',
    shares: 'Voer nummer in',
    custom_notary:
      'Vermeld hier de naam, plaats en telefoonnummer van de notaris die de legalisatie zal uitvoeren:',
    custom_notary_city:
      'Wat is de plaats waar je graag bij een notaris langs wil gaan?'
  },

  options: {
    industry: {
      business_services: 'Zakelijke dienstverlening',
      construction: 'Bouw',
      health: 'Gezondheid',
      retail: 'Detailhandel',
      culture_sport_recreation: 'Cultuur, sport en recreatie',
      ict_media: 'ICT en Media',
      wholesale: 'Groothandel',
      agriculture: 'Landbouw',
      manufacturing: 'Maakindustrie',
      logistics: 'Logistiek',
      hospitality: 'Horeca',
      financial_services: 'Financiële diensten',
      energy_water_environment: 'Energie, water en milieu',
      other: 'Overig'
    },
    converted_from: {
      proprietorship: 'Eenmanszaak',
      vof: 'VOF'
    },
    sell_products: {
      consumers: 'Aan consumenten',
      companies: 'Aan ondernemingen',
      none: 'Niet van toepassing',
      consumers_and_businesses: 'Aan zowel consumenten als ondernemingen'
    },
    where_are_sold: {
      store_or_kiosk: 'In een winkel of kiosk',
      market: 'Op de markt',
      street_trade: 'Via straathandel',
      internet: 'Via internet',
      from_home: 'Vanuit huis',
      postorder: 'Per postorder',
      not_applicable: 'Niet van toepassing'
    },
    representation: {
      one_signature:
        'De bestuurder is zelfstandig (alleen) vertegenwoordigingsbevoegd',
      two_signatures:
        'De bestuurder is samen met een mede-bestuurder vertegenwoordigingsbevoegd'
    },
    financial_year_end: {
      this_year: this_year,
      next_year: next_year
    },
    type_of_conversion: {
      asset_liability: 'Activa / passiva omzetting',
      tax_free_transfer: 'Geruisloze omzetting',
      transfer_subject_to_tax: 'Ruisende omzetting',
      none: 'Ik weet het niet'
    },
    incorporation_speed: {
      medium: 'Ja, ik wil mijn oprichting in vijf werkdagen (+€500).',
      slow: 'Nee, oprichting in 15 werkdagen is prima (inbegrepen)'
    }
  },
  label_tooltip: {
    industry:
      'Is jouw sector er niet bij staat, kies je voor “overige”. We vragen later in het proces wat de precieze activiteiten zijn.',
    big_registration:
      'Het BIG-register is een Nederlands register, waarin het basisberoep is opgenomen van personen die werkzaam zijn in een aantal beroepsgroepen in de gezondheidszorg.',
    caregiver:
      "<span>Actief in de zorg? Je hebt mogelijk specifieke ”zorgstatuten”(WTZA) nodig. De notaris voert in dat geval extra controles uit. De extra kosten voor zorgstatuten zijn €999. Houd er rekening mee dat de oprichting langer duurt. Kijk op <a href='https://www.toetredingzorgaanbieders.nl/'>deze link</a> voor alle regels met betrekking tot deze zorgstatuten.</span>",
    company_name:
      "<span>De naam moet uniek zijn, zodat het herkenbaar is. Controleer via <a href='http://kvk.nl/zoeken'>kvk.nl/zoeken</a> of de naam al bestaat. Zo voorkom je problemen met bedrijven die dezelfde, of vergelijkbare naam hebben. Ben je nog niet zeker van de naam? Geen zorgen. De naam kan je later in het proces nog aanpassen. </span>",
    headquarters:
      "De statutaire zetel is vaak hetzelfde als de vestigingsplaats van de BV en bepaalt welke rechter bevoegd is bij een geschil. De zetel wordt ook wel de formele 'woonplaats' van de BV genoemd en zal worden opgenomen in je statuten.",
    office_address:
      'Weet je nog niet waar je de BV wil vestigen? Registreer de BV dan op je woonadres. Dit scheelt papierwerk en tijd. De notaris heeft dan geen huurovereenkomst nodig om je BV op te richten. Je kan het adres na oprichting nog kosteloos en eenvoudig zelf wijzigen via de KVK. <br>Let op: het adres van de BV moet een fysiek Nederlands adres zijn en kan geen virtueel kantooradres of briefadres zijn.',
    activities:
      'Hou het breed, maar wel afgestemd op de sector waarin je actief bent (bijvoorbeeld transport). Richt je een BV in de zorg of het speciaal onderwijs op? Neem dan contact op met Ligo. Hiervoor gelden extra kosten.',
    sell_products:
      'Voor de registratie bij de KvK hebben we deze gegevens nodig.',
    converted_from:
      'Heb jij vastgoed in de eenmanszaak of vof die je wil inbrengen in de BV? Neem dan contact op met Ligo via 020-3031043 voor informatie over bijkomende kosten en handelingen.',
    financial_year_end:
      'De meeste ondernemers kiezen ervoor om het eerste boekjaar aan het eind van het volgende jaar te laten aflopen om tijd en kosten te besparen. Let op: deze optie is niet mogelijk als je met een bestaande holding een BV gaat oprichten en de holding en BV een fiscale eenheid gaan vormen.',
    full_time_work:
      'Deze informatie is nodig om de BV in te schrijven bij de KvK. Heb je later meer fulltime medewerkers? Geen zorgen, dat kan je later eenvoudig kosteloos wijzigen bij de KvK.',
    part_time_work:
      'Deze informatie is nodig om de BV in te schrijven bij de KvK. Heb je later meer parttime medewerkers? Geen zorgen, dat kan je later eenvoudig kosteloos wijzigen bij de KvK.',
    employees_loan:
      'Stel je werknemers ter beschikking aan anderen of leen je ze uit tegen betaling? En hoort dit niet tot de activiteiten van je bedrijf, bijvoorbeeld omdat je geen uitzendbureau bent? De KvK wil dit dan graag van je weten, zodat er duidelijkheid is over of deze werknemers onder jou of iemand anders werken.',
    representation:
      "Als je vindt dat elke bestuurder in zijn eentje de BV mag vertegenwoordigen, kies dan voor 'alleen'. Vind je juist dat er altijd twee bestuurders nodig moeten zijn om bijvoorbeeld een bankrekening namens de BV te openen of een overeenkomst te sluiten? Kies dan voor 'samen'. Sommige ondernemers vinden verplicht 'samen' een fijne gedachte, anderen vinden het weer onnodig.",
    added_value:
      'Heb je dit soort waardevolle zaken of onroerend goed op je balans staan? Dan heb je hoogstwaarschijnlijk een ruisende of geruisloze omzetting nodig. Hiervoor is een akte van inbreng nodig (€550,-).',
    holding_structure:
      'Als jij ervoor kiest om gelijk een holdingstructuur op te richten, zijn er twee akten van inbreng nodig (twee maal €499,-). Om jouw structuur één geheel te laten vormen moet je eenmanszaak of vof namelijk eerst ingebracht worden in de holding en vervolgens vanuit de holding ingebracht worden in de werk-BV.',
    accountant:
      'Na het afnemen van de dienst volgt een korte intake met onze partner fiscalist, om de manier van omzetten te bespreken. Je kunt vervolgens de inbrengbeschrijving laten opstellen door je eigen accountant (AA) of via onze eigen fiscalist voor een aanvullend tarief.',
    value_of_shares:
      'Je kan hier geen "0" invullen. De waarde van 1 aandeel dient minimaal 0,01 euro te bedragen.',
    total_shares:
      'We raden aan om 120 aandelen uit te geven. Dit aantal is namelijk eenvoudig deelbaar en kan je dus makkelijk verdelen onder meerdere aandeelhouders.',
    type_of_conversion:
      '<span>Activa=passiva omzetting: je verkoopt alle bezittingen en schulden aan de BV, waar een koopovereenkomst activa=passiva voor nodig is (zie: contracten bibliotheek). Geruisloze en ruisende omzetting: je hebt een akte van inbreng nodig (twee keer bij een holdingstructuur). Je start met een intakegesprek met one fiscalist om de fiscale voor- en nadelen van de drie opties te bespreken. Zo weet je wat de voordeligste optie voor jouw situatie is en maak je de juiste keuze. <strong>Let op: Het is niet mogelijk om via Ligo de activa van de VOF/eenmanszaak op te splitsen naar meerdere nieuwe BV’s (gedeeltelijke doorzakking).</strong></span>'
  }
};
