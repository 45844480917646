const date = new Date();
const this_year = `31-12-${date.getFullYear().toString()}`;
const next_year = `31-12-${(date.getFullYear() + 1).toString()}`;

export default {
  labels: {
    bv_type: 'Which type of BV do you want to incorporate?',
    email: 'Email address contact person',
    company_name: 'What will be the name of your new BV?',
    headquarters: 'Statutory Seat',
    office_address: 'Address',
    place_of_business: '',
    postal_code: '',
    industry: ' In which sector will your BV be active?',
    caregiver:
      'Do you work directly with patients and/or do you invoice directly to Health Insurance Companies?',
    big_registration:
      'Are you registered as healthcare provider in the BIG-register?',
    activities: 'Describe what the activities of the company are going to be',
    sell_products:
      'Does the company sell products to consumers or to businesses?',
    where_are_sold:
      'Where will these products be sold? (select all that apply)',
    export_products: 'Will the company export products and/or services?',
    import_products: 'Will the company import products and/or services?',
    number_of_shares: 'Total Shares in BV',
    value_of_shares: 'What will be the value of a share?',
    financial_year_end:
      'When does the first financial year of the company end?',
    full_time_work:
      'How many persons work full-time (15 hours or more per week) at the company?',
    part_time_work:
      'How many persons work part-time (less than 15 hours per week) at the company?',
    employees_loan:
      'Does the company occasionally lend out employees to other companies?',
    representation: 'How can the director represent the BV?',
    custom_notary: 'Notary that will perform the legislation',
    custom_notary_city: 'Your area',
    lead_approval:
      'Would you like to receive 3 free quotes from our selected bookkeepers from your region without obligation?',
    incorporation_speed: 'Would you like to incorporate your business faster?'
  },
  placeholders: {
    email: 'Email address contact person',
    headquarters: 'Company Location',
    office_address: 'Fill in the streetname and housenumber',
    place_of_business:
      'Fill in the name of the city where the office of your BV is located',
    company_name: 'Fill in the name of your BV',
    holding_name: 'Type here your holding name',
    postal_code: 'Fill in the Zip code',
    website: 'Enter the website address',
    industry: 'Enter industry type (e.g. retail; consulting)',
    activities: 'Describe the activities of the company',
    sell_products: 'Select from the list ',
    where_are_sold: 'Select from the list',
    total_shares: 'Total shares',
    value_of_shares: 'Fill in value',
    enter_number: 'Enter Number',
    financial_year_end: '',
    full_time_work: 'Enter Number',
    part_time_work: 'Enter Number',
    number_of_shares: 'Enter the number of share',
    value_shares: 'Enter the valuation of share',
    shares: 'Enter number',
    custom_notary:
      'Fill in name, city and phone number of the notary that will perform the legislation',
    custom_notary_city: 'Fill in Information about your area'
  },
  options: {
    industry: {
      business_services: 'Business services',
      construction: 'Construction',
      health: 'Healthcare',
      retail: 'Retail',
      culture_sport_recreation: 'Culture, sport and recreation',
      ict_media: 'ICT and Media',
      wholesale: 'Wholesale',
      agriculture: 'Agriculture',
      manufacturing: 'Manufacturing',
      logistics: 'Logistics',
      hospitality: 'Hospitality',
      financial_services: 'Financial services',
      energy_water_environment: 'Energy, water and environment',
      ngo: 'NGO',
      other: 'Other'
    },
    bv_type: {
      standard: 'Standard BV',
      private_savings: 'Private savings BV'
    },
    sell_products: {
      consumers: 'To consumers',
      companies: 'To businesses',
      none: 'Not applicable',
      consumers_and_businesses: 'To both consumers and businesses'
    },
    where_are_sold: {
      store_or_kiosk: 'In a shop or kiosk',
      market: 'At a market',
      street_trade: 'By way of street trading ',
      internet: 'On the internet ',
      from_home: 'From home',
      postorder: 'By mailorder',
      not_applicable: 'Does not apply'
    },
    representation: {
      one_signature:
        'The director is independently authorized to represent the BV by himself',
      two_signatures:
        'The director is authorized to represent the BV jointly with a co-director'
    },
    financial_year_end: {
      this_year: this_year,
      next_year: next_year
    },
    incorporation_speed: {
      medium: 'Yes, I want my incorporation in 5 working days (+€500).',
      slow: 'No, I am fine with incorporating in 15 working days (included)'
    }
  },
  label_tooltip: {
    industry:
      'If your sector is not listed, choose “other”. We will ask later in the process what the precise activities are.',
    caregiver:
      "<span>If you perform caregiving activities, you may require specific ’healthcare articles of association’ (WTZA). This involves additional checks and work by the notary, with extra costs of €999. Please note that incorporation will take longer. Check <a href='https://www.toetredingzorgaanbieders.nl/'>this</a> for all applicable rules regarding these requirements.</span>",
    big_registration:
      'he BIG register is a Dutch register that includes the basic profession of individuals working in several healthcare professions.',
    company_name:
      "<span>The name must be unique so that it is recognizable. Check via <a href='http://kvk.nl/zoeken'>kvk.nl/zoeken</a> whether the name already exists. This way you avoid problems with companies that have the same, or similar name. Not sure about the name yet? No problem. Later in the process you can still change the name.</span>",
    office_address:
      'Haven’t decided yet the address of your new BV? Then register the BV at your residential address. This saves time and paperwork, since the notary will not require a rental lease agreement to incorporate your BV. You can still change the address easily and free of charge at the Chamber of Commerce after the incorporation.<br> Note: The company address must be a physical Dutch address and cannot be a virtual office address or PO box.',
    headquarters:
      "The registered office is often the same as the place of business of the BV and determines which court has jurisdiction in a dispute. The registered office is also referred to as the formal 'residence' of the BV and will be included in your articles of association.",
    activities:
      'Keep it broad, but limited to the sector in which you are active (e.g. transport). Are you setting up a BV in healthcare or special education? Then contact Ligo at +31 20 303 1043 since additional costs may apply.',
    sell_products: 'We need this information for the submission at the KvK.',
    financial_year_end:
      'The fiscal year is a period for which you prepare the annual accounts of the BV. If you set up a BV, you opt for a short or extended first fiscal year. Most entrepreneurs opt for an extended first fiscal year, because this period runs until the end of the following year. Only then, annual accounts are drawn up for the first time and therefore you save costs. Please note: Are you setting up this BV with an existing holding company, which together will form a fiscal unity? Then an extended first fiscal year is not possible.',
    full_time_work:
      "This information is required to register the BV with the Chamber of Commerce. Will you have more full-time employees in the future? Don't worry, you can easily change this information later free of charge at the Chamber of Commerce.",
    part_time_work:
      'This information is required to register the BV with the Chamber of Commerce. Will you have more part-time employees in the future? Don’t worry, you can easily change this information later free of charge at the Chamber of Commerce.',
    employees_loan:
      "Do you make your employees available to others or do you lend them out for a fee? And is this not part of your company's activities, for example because you are not an employment agency? If you do, the Chamber of Commerce wants to know in order to create clarity on who your employees are actually working for.",
    representation:
      "If you think that each director should be able to represent the BV on his own, then choose 'alone'. Are you of the opinion that two directors should always be needed to, for example, open a bank account on behalf of the BV or conclude an agreement? Then choose 'together'. Some entrepreneurs find mandatory 'together' a nice idea, others find it unnecessary.",
    value_of_shares:
      'The par value is the value for which a share is issued. The share capital is the total amount of the nominal value of the shares at incorporation. You cannot enter "0" here. The value of 1 share must be at least EUR 0.01.',
    total_shares:
      'We recommend issuing 120 shares. This number is easily divisible and can therefore easily be divided among several shareholders.',
    incorporation_speed:
      'At this moment it is very busy at notaries throughout the Netherlands and at the Chamber of Commerce. The time of incorporation starts from the moment all documents are approved'
  }
};
