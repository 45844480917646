





































































































import {
  defineComponent,
  computed,
  ComputedRef,
  watch
} from '@vue/composition-api';
import {
  socialBtns,
  socialAuth,
  useLogin,
  providerLinks,
  SocialProvider
} from './auth';
import { useLoginFields } from './user_info';
import { authRedirection, loadProductType } from '@ligo/bv-flow/services';
import { ApiService, ChatBase } from '@ligo/shared/utils';
import { Analytic } from '../../analytics';
import BannerError from './BannerError.vue';
import { AuthError } from '@ligo/bv-flow/store';
import NextButton from '../../components/base/NextButton.vue';
import BackButton from '../../components/base/BackButton.vue';
import NewContactCard from '../../components/base/NewContactCard.vue';
import { analyticGTMSelectLogin } from '../../hooks/useBvFlowGTM.hooks';
import { RESOURCES, UserInfo } from '@ligo/bv-flow/store';

export default defineComponent({
  name: 'Login',
  components: { BackButton, BannerError, NextButton, NewContactCard },
  props: {
    redirectUrl: {
      type: String
    },
    productType: {
      type: String
    }
  },
  setup(props, { root }) {
    Analytic.registerPage('Login');

    const loginError: ComputedRef<AuthError> = computed(() => {
      return root.$store.state.authentication.authenticationError;
    });

    const loggedIn = computed(() => {
      return root.$store.state.authentication.accessToken ? true : false;
    });

    let handler = useLogin();
    if (loggedIn.value) {
      authRedirection(props.redirectUrl, props.productType);
    }

    const { login_fields } = useLoginFields();

    const performSocialAuth = (provider: SocialProvider) => {
      if (props.redirectUrl) {
        socialAuth(provider, props.redirectUrl);
      } else {
        const product_type = loadProductType();
        if (product_type) {
          socialAuth(provider, product_type);
        } else {
          socialAuth(provider);
        }
      }
    };

    const performLogin = async () => {
      const result: boolean = await handler.performLogin(login_fields.value);
      if (result) {
        analyticGTMSelectLogin(props.productType || loadProductType());
        Analytic.registerUser(root.$store.state.authentication.accessToken);

        ChatBase.initConversationService();
        ApiService.get(RESOURCES.ME)
          .then((response) => {
            const userInfo = response as UserInfo;
            const user = userInfo.data;
            ChatBase.identifyUser(user.uuid, user.user_hash, {
              name: `${user.firstname} ${user.lastname}`,
              email: user.email,
              phonenumber: user.phone
            });
          })
          .catch((e) => {
            console.error(
              `There was an error identifying the user in ChatBase: ${e}`
            );
          });

        authRedirection(props.redirectUrl, props.productType);
      } else {
        localStorage.removeItem('user');
      }
      return false;
    };

    const goToRegister = () => {
      cleanErrors();
      root.$router.push({
        name: 'register',
        query: {
          redirect_url: props.redirectUrl,
          product_type: props.productType
        }
      });
    };

    const cleanErrors = () => {
      root.$store.commit('authentication/cleanErrors');
    };

    const goBack = () => {
      let redirect_url = root.$route.query['redirect_url'].toString();
      if (redirect_url.includes('/billing')) {
        redirect_url = redirect_url.replace('/billing', '');
      }
      void root.$router.push(redirect_url);
    };

    const showGoBack = () => {
      if (root.$route.query['redirect_url']) return true;
    };

    watch(
      () => loginError.value,
      (newValue) => {
        if (newValue?.code == 'different_provider' && newValue.data.provider) {
          performSocialAuth(newValue.data.provider);
        }
      }
    );

    return {
      performLogin,
      socialAuth,
      performSocialAuth,
      cleanErrors,
      goToRegister,
      goBack,
      showGoBack,
      socialBtns,
      login_fields,
      providerLinks,
      loginError
    };
  }
});
