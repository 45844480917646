





import { defineComponent, onMounted } from '@vue/composition-api';
import { initAnalytics } from '@ligo/shared/analytics';
import { ApiService, ChatBase } from '@ligo/shared/utils';
import { UserService } from '@ligo/bv-flow/services';
import { useEnvironment } from '../../../netlify_functions';
import { RESOURCES, UserInfo } from '@ligo/bv-flow/store';

export default defineComponent({
  name: 'App',
  setup(_, { root }) {
    UserService.mountInterceptor();
    ChatBase.initConversationService();
    onMounted(() => {
      if (root.$store.state.authentication.accessToken) {
        ApiService.get(RESOURCES.ME)
          .then((response) => {
            const userInfo = response as UserInfo;
            const user = userInfo.data;
            ChatBase.identifyUser(user.uuid, user.user_hash, {
              name: `${user.firstname} ${user.lastname}`,
              email: user.email,
              phonenumber: user.phone
            });
            ChatBase.openWidget();
          })
          .catch((e) => {
            console.error(
              `There was an error identifying the user in Chatbase: ${e}`
            );
          });
      }
    });
  },

  beforeCreate() {
    initAnalytics(useEnvironment().SEGMENT_KEY);
  }
});
