import alerts from './alerts';
import validations from './validations';
import auth from './auth';
import user from './user';
import { dutch_bv } from './dutch-bv';
import { holding } from './holding';
import { english_bv } from './english-bv';
import { incorporate_holding } from './incorporate-holding';
import { omzetting } from './omzetting';
import { stichting } from './stichting';
import { transfer_of_shares } from './transfer-of-shares';

export default {
  info: 'Click for more info',
  en: 'English',
  nl: 'Dutch',
  go_back: 'Go Back',
  yes: 'Yes',
  no: 'No',
  next: 'Next',
  continue: 'Continue',
  proceed_to_payment: 'Proceed to payment',
  welcome: 'Welcome',
  welcome_to_ligo: 'Welcome To Ligo!',
  back: 'back',
  register: 'Who is the main contact person', //Nameles BV
  now: 'now!',
  bv_oprichten: 'BV oprichten',
  bv_english: 'BV english',
  omzetten: 'Eenmanszaak of VOF omzetten',
  cards: {
    'dutch-bv': 'BV oprichten',
    'english-bv': 'BV english',
    holding: 'Holding Oprichten',
    omzetting: 'Omzetting naar BV van eenmanszaak of vof',
    stichting: 'Stichting oprichten'
  },
  services: {
    'dutch-bv': 'BV oprichten',
    'english-bv': 'BV incorporation in English',
    bv_plus_holding: 'BV + Personal Holding(s) Incorporation',
    holding: 'Personal holding oprichten',
    omzetting: 'Omzetting naar BV van eenmanszaak of VOF',
    stichting: 'Stichting oprichten',
    anbi_stichting_oprichten: 'Anbi Stichting oprichten',
    transfer_of_shares: 'Aandelenoverdracht',
    trademark_registration: 'Trademark registration',
    trademark_search: 'Trademark search',
    trademark_monitoring: 'Trademark monitoring',
    draft_terms: 'Draft general terms and conditions',
    check_terms: 'Check general terms and conditions',
    debt: 'Debt collection',
    'incorporate-holding': 'Personal holding incorporation'
  },
  service_title: {
    part1: 'Which',
    part2: 'service',
    part3: 'would you like to start?'
  },
  service_subtitle:
    'On average it takes 10 minutes to complete the questionnaire of a service. Download your draft deed immediately after completing it.',
  bv_service_subtitle: {
    part1: 'What',
    part2: 'type of BV',
    part3: 'would you like to incorporate?'
  },
  bv_service_title:
    "Congratulations! You've taken the first step to setup your BV",
  bv_services: {
    bv_plus_holding: 'BV + Personal Holding(s) Incorporation',
    bv: 'BV incorporation in English',
  },
  bv_services_tooltip: {
    bv_plus_holding:
      'The holding structure is the most popular: a normal working BV with personal holding company. 90% of our customers choose this. Pay less tax, protect valuables and, for example, provide yourself with a mortgage loan.',
    bv:
      'This is a normal BV, which is also called a working BV. The shareholders own the BV. In principle, the directors are not personally liable for the debts of the BV.',
  },
  questions: 'Do you have questions? Call us at ', //phone_number
  free: 'FREE',
  dutch_bv: dutch_bv,
  holding: holding,
  english_bv: english_bv,
  incorporate_holding: incorporate_holding,
  omzetting: omzetting,
  stichting: stichting,
  transfer_of_shares: transfer_of_shares,

  type_title: 'The shareholder is a: ',
  type_message:
    'Do you also need a personal holding? You can set it up for € 310 instead of € 350.',
  add_shareholder: 'Add shareholder',
  natural_person: 'Natural Person',
  existing_company: 'Existing holding',
  new_company: 'New holding',
  incorporate: 'incorporate',
  your_bv: 'Your BV for',
  total_price: 'Total Price',
  select: 'Select',
  oprichting: 'Incorporating',
  language_acclaration:
    'If one of the shareholders of the BV cannot speak, read or write Dutch, it is only possible to incorporate the BV in English. If one of the shareholders of the BV cannot speak, read or understand both Dutch and English, unfortunately you cannot incorporate your BV at Ligo.',
  main_person: 'Please provide information of the holding’s director',
  new_main_person: 'Who will be the incorporator/director of the new holding?',
  lorem_ipsum:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id duis sed semper quis. Volutpat ultrices ipsum ornare quis facilisis metus venenatis. Etiam ridiculus id sed mattis vehicula.',
  empty: '',
  space: '*',
  sad_to_see_you_go: 'We are sad to see you go',
  dont_worry: "But don't worry!",
  come_back: 'you can comeback whenever you want',
  need_help: 'Need some help to continue?',
  have_question: 'Do you have questions?',
  call_us: 'Call us at',
  stay_finish: 'Stay & Finish',
  exit: 'Exit',
  shareholder: 'Shareholder',
  already_a_user: 'Are you already a Ligo member? ',
  alerts: alerts,
  validations: validations,
  auth: auth,
  user: user,
  btw: 'VAT',
  of: 'of',
  go_home:
    'Are you sure you want to move away from this screen? You can always continue this from your dashboard.',
  kiyoh_reviews: 'on Kiyoh',
  google_reviews: 'on Google',
  checkout: {
    package: 'Package',
    services: 'Extras',
    fees: 'Statutory fees & legal contribution',
    discount: 'Discounted',
    contracts: 'Unlimited access to 100+ contracts',
    total_description:
      'You can deduct the VAT and incorporation costs of your new BV'
  },
  extra_services: {
    title_prefix: 'A',
    title_primary: 'flying start',
    title_suffix: 'with these promotions of Ligo partners'
  },
  dga_global: {
    for: 'DGA for',
    per_month_pay_yearly: 'per month, paid yearly',
    discount_badge: '% off'
  },
  notary: {
    subtitle:
      'You can choose a notary who will do the service 100% online, or visit the notary.',
    info:
      'Please note: for 100% online incorporations, a civil-law notary may require you to visit the office in a very limited number of cases. Are you setting up your BV with foreign shareholders? In that case, the notary may indicate that 100% online incorporation is not possible. Contact our support team to know more.',
    online: 'Remote online notarization',
    online_pros: ['Fast', 'No travel costs', 'Easy via video call'],
    in_person: 'In person',
    in_person_pros: ['Personal meeting at office']
  },
  layout: {
    en: 'English',
    nl: 'Dutch'
  },
  holding_global: {
    high_recommend: 'Higly recommended'
  },
  footer_message:
    'More than <span style="color:#37CFB1">100.000</span> entrepreneurs use Ligo',
  contact_card: {
    kyiho_reviews: ' on Kiyoh',
    subtitle: 'Our expert legal team is here for you.',
    satisfied_customer: 'SATISFIED <br>CONSUMERS',
    need_help: 'Need some help?',
    talk_via_chat: 'Chat with our expert legal team',
    open_chat: 'Open chat',
    talk_via_call: 'Prefer calling?',
    make_call: 'Call us at +31 020 303 1043',
    schedule_meeting: 'Pick a time to call you later'
  },
  np_steps: {
    calculate: 'Calculate your price',
    details: 'Add your BV details',
    payment: 'Pay',
    docs: 'Send docs to notary',
    ready_bv: 'Your BV is ready'
  },
  calculator: {
    continue: 'Continue',
    complete: 'Complete',
    skip: 'No, skip',
    checkout: {
      total: 'Total',
      subtotal: 'Subtotal',
      vat: 'VAT',
      continue: 'Continue to add BV details'
    }
  },
  payment_error: 'There was an error',
  global_dialogs: {
    mandatory_director: {
      title:
        'One of the shareholders needs to be appointed as director. Choose at least one shareholder that will become the director of the BV to be able to continue.',
      label_cta: 'Ok, go back'
    }
  },
  errors: {
    error404: {
      text1: "Oh, oh... we've lost your track.",
      text2: 'Have fun finding your way back.',
      buttonText: 'Go back Home',
      or: 'or'
    }
  },
  go_to_results: 'Go to results',
  service_unavailable: 'At the moment, Ligo does not facilitate the incorporation of foundations, including ANBI foundations. However, we are happy to assist you further. We can refer you to one of our notaries who can support you with this process. Please feel free to contact us using the details below. We are here to help!'
};
