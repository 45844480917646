import { Dictionary } from '../../tools/types';

class ChatbaseService {
  initConversationService() {
    (function () {
      const w = window as any;
      if (!w.chatbase || w.chatbase('getState') !== 'initialized') {
        w.chatbase = (...args) => {
          if (!w.chatbase.q) {
            w.chatbase.q = [];
          }
          w.chatbase.q.push(args);
        };
        w.chatbase = new Proxy(w.chatbase, {
          get(target, prop) {
            if (prop === 'q') {
              return target.q;
            }
            return (...args) => target(prop, ...args);
          }
        });
      }
      const onLoad = function () {
        const script = document.createElement('script');
        script.src = 'https://www.chatbase.co/embed.min.js';
        script.id = 'IqGwjOAJlM8rW8fbiFMFE';
        // @ts-expect-error - domain is not a valid property of script
        script.domain = 'www.chatbase.co';
        document.body.appendChild(script);
      };
      if (document.readyState === 'complete') {
        onLoad();
      } else {
        window.addEventListener('load', onLoad);
      }
    })();
  }

  identifyUser(user_id: string, user_hash: string, user_metadata: Dictionary) {
    const w = window as any;
    w.chatbase('identify', {
      user_id,
      user_hash, // this is the hash of the user_id, should be generated on the server
      user_metadata
    });
  }

  openWidget() {
    const w = window as any;
    w.chatbase.open();
  }

  closeWidget() {
    const w = window as any;
    w.chatbase.close();
  }
}

export const ChatBase = new ChatbaseService();
