import alerts from './alerts';
import validations from './validations';
import auth from './auth';
import user from './user';

import { dutch_bv } from './dutch-bv';
import { holding } from './holding';
import { english_bv } from './english-bv';
import { omzetting } from './omzetting';
import { stichting } from './stichting';
import { anbi_stichting_oprichten } from './anbi_stichting_oprichten';
import { incorporate_holding } from './incorporate-holding';
import { transfer_of_shares } from './transfer-of-shares';

export default {
  info: 'Klik voor meer info',
  en: 'Engels',
  nl: 'Nederlandse',
  go_back: 'Ga terug',
  yes: 'Ja',
  no: 'Nee',
  next: 'Volgende',
  continue: 'Ga verder',
  proceed_to_payment: 'Ga door naar betaling',
  welcome: 'Welkom',
  welcome_to_ligo: 'Welkom bij Ligo!',
  back: 'back',
  register: 'Wie is de contactpersoon', //Nameles BV
  now: 'now!',
  cards: {
    'dutch-bv': 'Oprichting BV',
    'english-bv': 'Incorporation BV in English',
    holding: 'Holding Oprichten',
    omzetting: 'Omzetting naar BV van eenmanszaak of vof',
    stichting: 'Stichting oprichten',
    transfer_of_shares: 'Aandelenoverdracht'
  },
  services: {
    'dutch-bv': 'BV oprichten',
    'english-bv': 'BV incorporation in English',
    bv_plus_holding: 'BV + Persoonlijke Holding(s) Oprichten',
    holding: 'Persoonlijke holding oprichten',
    omzetting: 'Omzetting naar BV van eenmanszaak of VOF',
    stichting: 'Stichting oprichten',
    anbi_stichting_oprichten: 'Anbi Stichting oprichten',
    transfer_of_shares: 'Aandelenoverdracht',
    trademark_registration: 'Merkregistratie',
    trademark_search: 'Merkonderzoek',
    trademark_monitoring: 'Merkbewaking',
    draft_terms: 'Algemene voorwaarden nakijken',
    check_terms: 'Algemene voorwaarden opstellen',
    debt: 'Incasso',
    'incorporate-holding': 'Personal holding incorporation'
  },
  service_title: {
    part1: 'Welk',
    part2: 'dienst',
    part3: 'wil je starten?'
  },
  service_subtitle:
    'Het duurt gemiddeld 10 minuten om een vragenlijst van een dienst af te ronden. Download daarna meteen jouw conceptakte.',
  bv_service_subtitle: {
    part1: 'Welk',
    part2: 'type BV',
    part3: 'wil je oprichten?'
  },
  bv_service_title:
    'Gefeliciteerd! Je hebt de eerste stap genomen om je BV op te richten',
  bv_services: {
    bv_plus_holding: 'BV + Persoonlijke Holding oprichten',
    bv: 'BV oprichten',
  },
  bv_services_tooltip: {
    bv_plus_holding:
      'De holdingstructuur is het populairst: een normale werk-BV met persoonlijke holding. 90% van onze klanten kiest dit. Betaal minder belasting, bescherm waardevolle zaken en verstrek bijv. een hypotheeklening aan jezelf.',
    bv:
      'Dit is een normale BV, ook wel een werk-BV. De aandeelhouders zijn eigenaar van de BV. De bestuurders zijn in principe niet persoonlijk aansprakelijk voor schulden van de BV.',
  },
  questions: 'Heb je vragen? Bel ons op ', //phone_number
  incorporate: 'oprichten',
  language_acclaration:
    "Belangrijk: In het geval een van de aandeelhouders niet in het Nederlands kan spreken, lezen en schrijven, moet u de BV-oprichting starten in het Engels. Op voorwaarde echter dat zo'n aandeelhouder in het Engels kan spreken, lezen en schrijven. Anders kan de notaris de oprichting niet starten",
  main_person: '<p>Vul de gegevens in van de bestuurder van de holding:</p>',
  new_main_person:
    '<p>Wie wordt de oprichter/directeur van de nieuwe holding?</p>',
  lorem_ipsum:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id duis sed semper quis. Volutpat ultrices ipsum ornare quis facilisis metus venenatis. Etiam ridiculus id sed mattis vehicula.',
  empty: '',
  space: '*',
  already_a_user: 'Ben je al lid van Ligo?',
  sad_to_see_you_go: 'We vinden het jammer dat je stopt',
  dont_worry: 'Maar maak je geen zorgen!',
  come_back: 'Je kan terugkomen wanneer je wil.',
  need_help: 'Heb je hulp nodig om verder te gaan?',
  have_question: 'Heb je vragen?',
  call_us: 'Bel ons op',
  stay_finish: 'Blijf en voltooi',
  exit: 'Beëindig',
  shareholder: 'Aandeelhouder',
  founders: 'oprichter',
  alerts: alerts,
  validations: validations,
  auth: auth,
  user: user,
  free: 'GRATIS',
  dutch_bv: dutch_bv,
  holding: holding,
  english_bv: english_bv,
  incorporate_holding: incorporate_holding,
  omzetting: omzetting,
  stichting: stichting,
  anbi_stichting_oprichten: anbi_stichting_oprichten,
  transfer_of_shares: transfer_of_shares,
  type_title: 'De verkoper is een:',
  type_message:
    'Heeft u ook een persoonlijke holding nodig? Je kunt het opzetten voor € 310 in plaats van € 350.',
  natural_person: 'Natuurlijk persoon',
  existing_company: 'Bestaande holding',
  new_company: 'Nieuwe holding',
  existing: 'Bestaande',
  partnership: 'Onderneming',
  add_shareholder: 'Voeg aandeelhouder toe',
  add_founders: 'Voeg oprichter toe',
  total_price: 'totaalprijs',
  select: 'Kies',
  oprichting: 'Oprichting',
  btw: 'BTW',
  of: 'van',
  go_home:
    'Weet je zeker dat je dit scherm wil verlaten? Je kan altijd verder gaan vanuit je dashboard.',
  kiyoh_reviews: 'op Kiyoh',
  google_reviews: 'op Google',
  checkout: {
    package: 'Package',
    services: 'Extras',
    fees: 'Wettelijke bijdrage',
    discount: 'Korting',
    contracts: {
      professional: '5 must-have legal contracts',
      professionalPlus: 'Onbeperkt toegang tot 100+ contracten'
    },
    total_description:
      'U kunt de BTW en de oprichtingskosten van uw nieuwe BV aftrekken'
  },
  extra_services: {
    title_prefix: 'Maak',
    title_primary: 'een vliegende start',
    title_suffix: 'met deze acties van Ligo partners'
  },
  dga_global: {
    for: 'DGA voor',
    per_month_pay_yearly: 'per maand, jaarlijks betaald',
    discount_badge: '% korting'
  },
  notary: {
    subtitle:
      'Je kan een notaris kiezen die 100% online jouw BV opricht, of langsgaan bij de notaris.',
    info:
      'Let op: voor 100% online oprichtingen kan een notaris in zeer beperkt aantal gevallen verlangen dat je toch langskomt op kantoor. Richt je jouw BV op met buitenlandse aandeelhouders? In dat geval is het mogelijk dat de notaris aangeeft dat 100% online oprichting niet mogelijk is. Neem contact op met ons Support team voor meer informatie.',
    online: 'Online',
    online_pros: [
      'Snel geregeld',
      'Geen reiskosten',
      'Gemakkelijk via videocall'
    ],
    in_person: 'Langs bij de notaris',
    in_person_pros: ['Persoonlijke ontmoeting op kantoor']
  },
  layout: {
    en: 'Engels',
    nl: 'Nederlands'
  },
  holding_global: {
    high_recommend: 'Aanbevolen'
  },
  footer_message:
    'Meer dan <span style="color:#37CFB1">100.000</span> ondernemers gebruiken Ligo',
  contact_card: {
    kyiho_reviews: ' op Kiyoh',
    subtitle: 'Ons deskundig juridisch team is er voor je.',
    satisfied_customer: 'tevreden <br>klanten',
    need_help: 'Hulp nodig?',
    talk_via_chat: 'Chat met onze juridische adviseurs',
    open_chat: 'Open chat',
    talk_via_call: 'Bel jij liever?',
    make_call: 'Bel ons +31 020 303 1043',
    schedule_meeting: 'Kies een tijd om je later te bellen'
  },
  np_steps: {
    calculate: 'Bereken je prijs',
    details: 'Vul je BV gegevens to',
    payment: 'Betaal',
    docs: 'Stuur docs naar notaris',
    ready_bv: 'Je BV is klaar'
  },
  calculator: {
    continue: 'Doorgaan',
    complete: 'Afronden',
    skip: 'Nee, overslaan',
    checkout: {
      total: 'Totaal',
      subtotal: 'Subtotaal',
      vat: 'BTW',
      continue: 'Doorgaan met het toevoegen van BV details'
    }
  },
  payment_error: 'Er is een fout opgetreden',
  global_dialogs: {
    mandatory_director: {
      duplicated_title:
        'De optie van regisseursrollen kan telkens maar één keer worden geselecteerd',
      title:
        'Eén van de aandeelhouders moet worden aangewezen als bestuurder. Kies een aandeelhouder die ook bestuurder wordt van de BV om door te kunnen.',
      label_cta: 'Oké, ga terug'
    }
  },
  errors: {
    error404: {
      text1: 'Oh, oh... we zijn je onderweg kwijtgeraakt.',
      text2: 'Veel plezier met het terugvinden van je weg!',
      buttonText: 'Terug naar de homepagina',
      or: 'of'
    }
  },
  go_to_results: 'Ga naar resultaten',
  service_unavailable: 'Op dit moment richten we via Ligo geen stichtingen / ANBI stichtingen op. Maar geen zorgen, we helpen je graag verder! We kunnen je doorverwijzen naar een van onze notarissen die je hierbij kunnen ondersteunen. Neem gerust contact met ons op via onderstaande gegevens. We staan klaar om je verder te helpen.'
};
