export function useEnvironment(event, context) {
  if ("production" === 'production')
  return {
    API: 'https://www.ligo.nl/',
    WEB_SOCKET_SERVER: 'wss://www.ligo.nl/cable/',
    CUSTOMER_DASHBOARD: 'https://www.ligo.nl/dashboard/',
    QUESTIONNAIRE: 'https://www.ligo.nl/questionnaire/',
    MAGNEXUS: 'https://app.getmagnexus.com/',
    SEGMENT_KEY: 'CkmreIc9KUZ20SU1VqiXukYfsFoz8FJr',
    GTM_ID: 'GTM-KZRR455',
    STORYBLOK_TOKEN: 'Q3K1jzxHiaXeXVUoDEfUWgtt',
    APP_CONTEXT: 'production',
    ADYEN: {
      environment: 'live',
      clientKey: 'live_QA2555YEV5CBNLFX7LPGGASIF4GV5DEV' },

    SYNCFUSION_LICENSE:
    'ORg4AjUWIQA/Gnt2V1hiQlRPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXZTckVqXX9ecndSR2E=',
    MAPS_API_KEY: 'AIzaSyBkd9NOhtuDfuHptq7RXWhVbZrSXpMAKgw',
    FROALA_API_KEY:
    'MZC1rE1G4G4B3B15A10A5jF1QUg1Xc2OZE1ABVJRDRNGGUH1ITrA1C7A6E5E1E4H4E1B10D7==' };else

  if (
  "production" === 'deploy-preview' ||
  "production" === 'branch-deploy')

  return {
    API: 'https://staging.ligo.nl/',
    WEB_SOCKET_SERVER: 'wss://staging.ligo.nl/cable/',
    CUSTOMER_DASHBOARD: 'https://staging.ligo.nl/dashboard/',
    QUESTIONNAIRE: 'https://staging.ligo.nl/questionnaire/',
    MAGNEXUS: 'https://develop--magnexus-dashboard.netlify.app/',
    SEGMENT_KEY: 'GGIbpBfHQPyJy5sAiboHD9EjakrxfrpY',
    GTM_ID: 'GTM-W8WB9PP',
    STORYBLOK_TOKEN: 'NoOUok9ARPNwbBOVzKcaKQtt',
    APP_CONTEXT: 'staging',
    ADYEN: {
      environment: 'test',
      clientKey: 'test_NYF5OPL7O5ALBC7LOY5ANOJRNMBLMT3G' },

    SYNCFUSION_LICENSE:
    'ORg4AjUWIQA/Gnt2V1hiQlRPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXZTckVqXX9ecndSR2E=',
    MAPS_API_KEY: 'AIzaSyDyOXF1Xr4Agj9mSvdvpjBA1jkPXJDAflg',
    FROALA_API_KEY:
    'MZC1rE1G4G4B3B15A10A5jF1QUg1Xc2OZE1ABVJRDRNGGUH1ITrA1C7A6E5E1E4H4E1B10D7==' };else


  return {
    API: 'https://staging.ligo.nl/',
    WEB_SOCKET_SERVER: 'wss://staging.ligo.nl/cable/',
    CUSTOMER_DASHBOARD: 'https://staging.ligo.nl/dashboard/',
    QUESTIONNAIRE: 'https://staging.ligo.nl/questionnaire/',
    MAGNEXUS: 'https://develop--magnexus-dashboard.netlify.app/',
    SEGMENT_KEY: 'GGIbpBfHQPyJy5sAiboHD9EjakrxfrpY',
    GTM_ID: 'GTM-W8WB9PP',
    STORYBLOK_TOKEN: 'lbEXHj1b6zrruFtdrL6QjQtt',
    APP_CONTEXT: 'development',
    ADYEN: {
      environment: 'test',
      clientKey: 'test_NYF5OPL7O5ALBC7LOY5ANOJRNMBLMT3G' },

    SYNCFUSION_LICENSE:
    'ORg4AjUWIQA/Gnt2V1hiQlRPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXZTckVqXX9ecndSR2E=',
    MAPS_API_KEY: 'AIzaSyDyOXF1Xr4Agj9mSvdvpjBA1jkPXJDAflg',
    FROALA_API_KEY:
    'MZC1rE1G4G4B3B15A10A5jF1QUg1Xc2OZE1ABVJRDRNGGUH1ITrA1C7A6E5E1E4H4E1B10D7==' };

}